"use client";

import * as React from "react";
import Link from "next/link";
import { useSelectedLayoutSegment } from "next/navigation";

import { MainNavItem } from "types";
import { cn } from "@/lib/utils";
import { Icons } from "@/components/icons";
import { MobileNav } from "@/components/mobile-nav";
import { HeaderLogo } from "./header-logo";
import { buttonVariants } from "./ui/button";
import { ThemeToggle } from "./theme-toggle";
import { useTranslation } from "@/i18n/client";
import { fallbackLng } from "@/i18n/settings";

interface MainNavProps {
  items?: MainNavItem[];
  children?: React.ReactNode;
  lng?: string;
}

export function MainNav({
  items = [],
  children,
  lng = fallbackLng,
}: MainNavProps) {
  const { t } = useTranslation(lng, "common");
  const segment = useSelectedLayoutSegment();
  const [showMobileMenu, setShowMobileMenu] = React.useState<boolean>(false);
  const mobileItems = [
    ...items,
    {
      title: t("createAccount"),
      href: "https://panel.givetip.to/register.php",
    },
    {
      title: t("login"),
      href: "https://panel.givetip.to/login.php",
    },
  ];
  return (
    <>
      <div className="mx-auto flex max-w-7xl items-center justify-between p-6 backdrop-blur lg:px-8 xl:overflow-hidden xl:rounded-md">
        <div className="flex lg:flex-1 items-center space-x-2">
          <div className="h-[38px] w-[170px] ">
            <HeaderLogo />
          </div>
        </div>
        <nav className="hidden lg:flex lg:items-center lg:gap-x-12">
          {items?.length &&
            items?.map((item, index) => (
              <Link
                key={index}
                href={item.disabled || !item.href ? "#" : item.href}
                className={cn(
                  "flex items-center text-lg font-medium transition-colors hover:text-foreground/80 sm:text-sm",
                  item?.href?.startsWith(`/${segment}`)
                    ? "text-foreground"
                    : "text-foreground/60",
                  item.disabled && "cursor-not-allowed opacity-80"
                )}
              >
                {item.title}
              </Link>
            ))}
        </nav>
        <div className="flex lg:hidden">
          <button
            className="flex items-center space-x-2 md:hidden"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            {showMobileMenu ? <Icons.close /> : <Icons.menu />}
          </button>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end ">
          <ThemeToggle />
          <Link
            href="https://panel.givetip.to/register.php"
            className={cn(
              buttonVariants({ variant: "link", size: "sm" }),
              "px-4"
            )}
          >
            {t("createAccount")}
          </Link>
          <Link
            href="https://panel.givetip.to/login.php"
            className={cn(
              buttonVariants({ variant: "outline", size: "sm" }),
              "px-4"
            )}
          >
            {t("login")}
          </Link>
        </div>
      </div>
      {showMobileMenu && items && (
        <MobileNav items={mobileItems}>{children}</MobileNav>
      )}
    </>
  );
}
