"use client";

import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { languages } from "@/i18n/settings";
import type { ReactNode } from "react";
import { useMemo } from "react";
import type { Route } from "next";

type Language = (typeof languages)[number];
interface DropdownProps {
  availableLanguages: Array<{ language: Language; label: string }>;
  currentLanguage: Language;
  children: ReactNode;
}

/**
 * Split pathname on segments
 * @example
 * ```
 * splitPathName('/en/posts/some-post-slug') // return ['en', 'posts', 'some-post-slug']
 * ```
 * @param pathname
 * @returns segments
 */
function splitPathname(pathname: string): string[] {
  return pathname.split("/").filter(Boolean);
}

/**
 * Join segments and searchParams
 *
 * @example
 * ```
 * createPath(['en', 'posts', 'some-post-slug'], 'search=qwe&tags=typescript') // return '/en/posts/some-post-slug?search=qwe&tags=typescript'
 * ```
 *
 * @param segments
 * @param searchParamsString
 */
function createPath(segments: string[], searchParamsString: string): Route {
  return `/${segments.join("/")}${
    searchParamsString ? `?${searchParamsString}` : ""
  }` as Route;
}

export function Dropdown({
  availableLanguages,
  currentLanguage,
  children,
}: DropdownProps) {
  const pathname = usePathname();

  const searchParams = useSearchParams();

  const links = useMemo(() => {
    const [, ...segments] = splitPathname(pathname as string);

    const searchParamsString = searchParams?.toString() ?? "";

    return availableLanguages.map(({ language, label }) => ({
      href: createPath([language, ...segments], searchParamsString),
      language,
      label,
    }));
  }, [availableLanguages, pathname, searchParams]);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>{children}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className="bg-popover text-popover-foreground rounded transition-shadow py-3 animate-slideDown border border-popover-border">
          {links.map(({ href, language, label }) => (
            <DropdownMenu.Item
              key={language}
              disabled={language === currentLanguage}
              className="focus-visible:outline-none"
            >
              <Link
                prefetch={language !== currentLanguage}
                href={href}
                aria-disabled={language === currentLanguage}
                className="flex py-1 px-5 hover:bg-accent hover:text-accent-foreground transition-colors ring-offset-background focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 hover:cursor-pointer"
              >
                {label}
              </Link>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
