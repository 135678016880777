export const fallbackLng = "en";
export const languages = [fallbackLng, "nb", "da"];
export const defaultNS = "common";
export const cookieName = "i18next";

export function getOptions(
  lng = fallbackLng,
  ns: string | string[] = defaultNS
) {
  return {
    debug: process.env.NODE_ENV === "development",
    supportedLngs: languages,
    // preload: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
    reloadOnPrerender: process.env.NODE_ENV === "development",
    nonExplicitSupportedLngs: true,
    lowerCaseLng: true,
    partialBundledLanguages: true,
    no: ["nb", "en"],
    nb: ["nb", "en"],
    nn: ["nb", "en"],
    default: ["en"],
  };
}
