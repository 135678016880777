import * as React from "react";
import Link from "next/link";
import Image from "next/image";

import { cn } from "@/lib/utils";
export function HeaderLogo({ className }: { className?: string }) {
  return (
    <div className={cn("relative aspect-square h-full w-full", className)}>
      <Link href="/">
        <Image
          src="/img/cover_black.png"
          alt="GiveTip.to logo"
          fill
          className={"dark:invert"}
        />
      </Link>
    </div>
  );
}
