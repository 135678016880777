import * as React from "react";
import Link from "next/link";

import { MainNavItem } from "@/types";
import { cn } from "@/lib/utils";
import { useLockBody } from "@/hooks/use-lock-body";
import { HeaderLogo } from "./header-logo";

interface MobileNavProps {
  items: MainNavItem[];
  children?: React.ReactNode;
}

export function MobileNav({ items, children }: MobileNavProps) {
  useLockBody();

  return (
    <div
      className={cn(
        "w-screen fixed inset-0 top-16 grid h-[calc(100vh-4rem)] grid-flow-row auto-rows-max overflow-auto p-6 pb-32 shadow-md animate-in slide-in-from-bottom-80 md:hidden bg-background"
      )}
    >
      <nav className="grid grid-flow-row auto-rows-max text-sm">
        {items.map((item, index) => (
          <Link
            key={index}
            href={item.disabled || !item.href ? "#" : item.href}
            className={cn(
              "flex w-full items-center rounded-md p-2 text-sm font-medium hover:underline",
              item.disabled && "cursor-not-allowed opacity-60"
            )}
          >
            {item.title}
          </Link>
        ))}
      </nav>
      {children}
    </div>
  );
}
